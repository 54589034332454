<template>
  <b-sidebar id="sidebar-helicopterShop" :visible="isHelicopterSidebarActive" @change="val => $emit('update:is-helicopter-sidebar-active', val)">>
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-end align-items-center content-sidebar-header px-2 py-1">
        <!--        <b-link :to="{ name: 'cancellationPolicyEdit', params: { id: 3 } }" class="font-weight-bold">edit event</b-link>-->
        <feather-icon class="ml-1 cursor-pointer ppr-no-print" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <!-- FINALISATION-->
      <b-card-body v-if="!resume">
        <div>
          <b-link
            class="ml-25"
            :to="{name: 'trip-details', params: { trip_id: tripId }}"
            target="_blank"
          >{{ $t('common.edit_event') }}</b-link>
        </div>
        <h2 class="text-center mb-3">{{ $t('trip.shop.helicopter.sidebars.title1') }}</h2>
        <validation-observer ref="addShopHelicopterRules">
          <b-form>
            <!-- FIELDS -->
            <b-row>
              <b-col sm="12">
                <b-form-group class="select-options" :label="$t('trip.shop.departure_deposit_zone')" label-for="departure-deposit-zone">
                  <v-select
                    v-if="regularFlight"
                    v-model="quotationData.departureDepositZoneId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="departureDepositZoneOptions"
                    :reduce="val => val.id"
                    :clearable="false"
                    label="formattedAddress"
                    input-id="departure-deposit-zone"
                  />
                  <div v-else class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group>
                        <GmapAutocomplete
                          id="departure-address-on-quote"
                          ref="gmapDeparture"
                          class="form-control"
                          :value="quotationData.departureDepositZoneAddress ? getDetailedAddress(quotationData.departureDepositZoneAddress) : null"
                          @place_changed="setDepartureOnQuoteAddressPlace"
                        />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.departureDepositZoneAddress" target="_blank" :href="quotationData.departureDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <!-- OTHER DEPARTURE ADDRESS FOR REGULAR FLIGHT-->
                  <div v-if="quotationData.departureDepositZoneId === 0" class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group label-for="departure-address">
                        <GmapAutocomplete
                          id="departure-address"
                          ref="gmapDeparture"
                          class="form-control"
                          @place_changed="setDepartureAddressPlace"
                        />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.departureDepositZoneAddress" target="_blank" :href="quotationData.departureDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <div v-if="getDepartureAddressById(quotationData.departureDepositZoneId) && getDepartureAddressById(quotationData.departureDepositZoneId).latitude && getDepartureAddressById(quotationData.departureDepositZoneId).longitude">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ getDepartureAddressById(quotationData.departureDepositZoneId).latitude }} , {{ getDepartureAddressById(quotationData.departureDepositZoneId).longitude }}
                  </div>
                  <div v-else-if="quotationData.departureDepositZoneAddress">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ quotationData.departureDepositZoneAddress.latitude }} , {{ quotationData.departureDepositZoneAddress.longitude }}
                  </div>
                </b-form-group>
                <b-form-group class="select-options" :label="$t('trip.shop.arrival_deposit_zone')" label-for="arrival-deposit-zone">
                  <v-select
                    v-if="regularFlight"
                    v-model="quotationData.arrivalDepositZoneId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="arrivalDepositZoneOptions"
                    :reduce="val => val.id"
                    label="formattedAddress"
                    :clearable="false"
                    input-id="arrival-deposit-zone"
                  />
                  <div v-else class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group>
                        <GmapAutocomplete
                          id="arrival-address-on-quote"
                          ref="gmapDeparture"
                          class="form-control"
                          :value="quotationData.arrivalDepositZoneAddress ? getDetailedAddress(quotationData.arrivalDepositZoneAddress) : null"
                          @place_changed="setArrivalOnQuoteAddressPlace"
                        />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.arrivalDepositZoneAddress" target="_blank" :href="quotationData.arrivalDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <!-- OTHER ARRIVAL ADDRESS FOR REGULAR FLIGHT-->
                  <div v-if="quotationData.arrivalDepositZoneId === 0" class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group label-for="arrival-address">
                        <GmapAutocomplete id="arrival-address" ref="gmapDeparture" class="form-control" @place_changed="setArrivalAddressPlace" />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.arrivalDepositZoneAddress" target="_blank" :href="quotationData.arrivalDepositZoneAddress.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>

                  <div v-if="getArrivalAddressById(quotationData.arrivalDepositZoneId) && getArrivalAddressById(quotationData.arrivalDepositZoneId).latitude && getArrivalAddressById(quotationData.arrivalDepositZoneId).longitude">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ getArrivalAddressById(quotationData.arrivalDepositZoneId).latitude }} , {{ getArrivalAddressById(quotationData.arrivalDepositZoneId).longitude }}
                  </div>
                  <div v-else-if="quotationData.arrivalDepositZoneAddress">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ quotationData.arrivalDepositZoneAddress.latitude }} , {{ quotationData.arrivalDepositZoneAddress.longitude }}
                  </div>
                </b-form-group>
                <p v-if="arrivalDepositZoneOptions.length > 1 && departureDepositZoneOptions.length > 1">{{ $t('trip.shop.extra.title') }} {{ $t('trip.shop.extra.content') }}</p>
                <!--  DEPARTURE EXTRA  -->
                <b-form-checkbox v-model="departureExtra" name="departureExtra" class="custom-control-primary" switch>{{ $t('trip.shop.departure_extra') }}</b-form-checkbox>
                <div v-show="departureExtra">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group :label="$t('trip.shop.taking_charge_from')" label-for="departure-extra-address">
                        <GmapAutocomplete id="departure-extra-address" ref="gmapDeparture" class="form-control" @input="onChangeDepartureExtraAddress" @place_changed="setDepartureExtraAddressPlace" />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.departureTaxiServiceOption.address" target="_blank" :href="quotationData.departureTaxiServiceOption.address.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <p v-if="!departureExtraValid() && alerts.departureExtra" class="text-danger font-italic">{{ $t('trip.shop.taking_charge_from_invalid') }}</p>
                  <b-col class="pl-0" cols="12" lg="2">
                    <app-input
                      id="departure-extra-passenger"
                      v-model="quotationData.departureTaxiServiceOption.passenger"
                      :label="$t('trip.shop.passengers')"
                      step="any"
                      type="number"
                      rules="positive"
                    />
                  </b-col>
                </div>
                <!--  ARRIVAL EXTRA  -->
                <b-form-checkbox v-model="arrivalExtra" name="arrivalExtra" class="custom-control-primary mt-2" switch>{{ $t('trip.shop.arrival_extra') }}</b-form-checkbox>
                <div v-show="arrivalExtra">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group :label="$t('trip.shop.taking_charge_to')" label-for="arrival-extra-address">
                        <GmapAutocomplete id="arrival-extra-address" ref="gmapArrivalExtraAddress" class="form-control" @input="onChangeArrivalExtraAddress" @place_changed="setArrivalExtraAddressPlace" />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.arrivalTaxiServiceOption.address" target="_blank" :href="quotationData.arrivalTaxiServiceOption.address.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <p v-if="!arrivalExtraValid() && alerts.arrivalExtra" class="text-danger font-italic">{{ $t('trip.shop.taking_charge_to_invalid') }}</p>
                  <b-col class="pl-0" cols="12" lg="2">
                    <app-input
                      id="arrival-extra-passenger"
                      v-model.number="quotationData.arrivalTaxiServiceOption.passenger"
                      :label="$t('trip.shop.passengers')"
                      step="any"
                      type="number"
                      rules="positive"
                    />
                  </b-col>
                </div>
                <!--  ACCEPT SEPARATE LUGGAGE  -->
                <b-form-checkbox v-model="acceptSeparateLuggage" name="acceptSeparateLuggage" class="custom-control-primary mt-2" switch>{{ $t('trip.shop.accept_separate_luggage') }}</b-form-checkbox>
                <div v-show="acceptSeparateLuggage">
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group :label="$t('trip.shop.taking_charge_from')" label-for="transfer-from">
                        <GmapAutocomplete id="transfer-from" ref="gmapDeparture" class="form-control" @input="onChangeDepartureSeparateLuggageAddress" @place_changed="setTransferFromAddressPlace" />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.luggageTransferOption.from" target="_blank" :href="quotationData.luggageTransferOption.from.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <div class="d-flex justify-content-between align-items-center">
                    <label class="search-autocomplete_map">
                      <b-form-group :label="$t('common.to')" label-for="transfer-to">
                        <GmapAutocomplete id="transfer-to" ref="gmapDeparture" class="form-control" @input="onChangeArrivalSeparateLuggageAddress" @place_changed="setTransferToAddressPlace" />
                      </b-form-group>
                    </label>
                    <b-link v-if="quotationData.luggageTransferOption.to" target="_blank" :href="quotationData.luggageTransferOption.to.url"><font-awesome-icon icon="arrow-up-right-from-square" /></b-link>
                  </div>
                  <p v-if="!separateLugguageValid() && (alerts.luggageTransferOption.from || alerts.luggageTransferOption.to)" class="text-danger font-italic">{{ $t('trip.shop.serparate_lugguage_invalid') }}</p>
                  <div class="d-flex">
                    <b-col class="pl-0" cols="12" lg="2">
                      <app-input
                        id="extra-luggage"
                        v-model.number="quotationData.luggageTransferOption.luggage"
                        :label="$t('luggage.luggage')"
                        step="any"
                        type="number"
                      />
                    </b-col>
                    <b-col class="pl-0" cols="12" lg="2">
                      <app-input
                        id="extra-bags"
                        v-model.number="quotationData.luggageTransferOption.bags"
                        :label="$t('luggage.bags')"
                        step="any"
                        type="number"
                      />
                    </b-col>
                  </div>

                </div>
                <label class="mt-2" for="additional-informations">{{ $t('service.additional_informations.title') }}</label>
                <b-form-textarea
                  id="additional-informations"
                  v-model="quotationData.additionalInformations"
                  :placeholder="$t('service.additional_informations.title')"
                />
              </b-col>
            </b-row>
            <!-- FOOTER -->
            <div class="mt-3">
              <b-button :disabled="error || !isValidForm()" class="mb-1 w-100" @click="validationForm()">
                <font-awesome-icon class="mr-50" icon="calendar-check" />
                {{ $t('action.validate') }}
              </b-button>

              <b-button class="w-100" variant="outline-secondary" @click="hide">
                <font-awesome-icon class="mr-50" icon="ban" />
                {{ $t('action.cancel') }}
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card-body>
      <!-- CONFIRMATION -->
      <b-card-body v-else>
        <b-overlay :show="requestSending" spinner-medium>

          <div>
            <b-link
              class="ml-25"
              :to="{name: 'trip-details', params: { trip_id: tripId }}"
              target="_blank"
            >{{ $t('common.edit_event') }}</b-link>
          </div>
          <h2 class="text-center mb-3">{{ $t('trip.shop.helicopter.sidebars.title2') }}</h2>
          <!-- FIELDS -->
          <b-row class="mb-3">
            <b-col sm="6" class="mb-2">
              <b-card-sub-title class="mb-1"><font-awesome-icon icon="clock" class="mr-50" style="width: 25px" /><span>{{ $t('trip.event.etd') }}</span></b-card-sub-title>
              <b-card-text>{{ $moment.utc(eventDetail.transferDeparture.eventDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</b-card-text>
              <b-card-text>{{ $moment.utc(eventDetail.transferDeparture.eventDateTime).utcOffset(quotationToSend.departureDepositZoneAddress.timeZone).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('trip.event.local_time') }}</b-card-text>
            </b-col>
            <b-col sm="6" class="mb-2">
              <div v-if="eventArrivalDateTime && quotationData.departureDepositZoneId !== 0 && quotationData.arrivalDepositZoneId !== 0">
                <b-card-sub-title class="mb-1"><font-awesome-icon icon="clock" class="mr-50" style="width: 25px" /><span>{{ $t('trip.event.eta') }}</span></b-card-sub-title>
                <b-card-text>{{ $moment.utc(eventArrivalDateTime).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('common.utc') }}</b-card-text>
                <b-card-text>{{ $moment.utc(eventArrivalDateTime).utcOffset(quotationToSend.arrivalDepositZoneAddress.timeZone).format('ddd, MMM Do YYYY HH:mm') }} {{ $t('trip.event.local_time') }}</b-card-text>
              </div>
            </b-col>
            <b-col sm="6" class="mb-2">
              <b-card-sub-title class="mb-1"><font-awesome-icon icon="plane" class="mr-50" style="width: 25px" /><span>{{ $t('trip.shop.departure_deposit_zone') }}</span></b-card-sub-title>
              <div v-if="quotationToSend.departureDepositZoneId > 0">
                <b-card-text v-for="departure in product.departureDepositZones" :key="departure.id">
                  <span v-if="departure.id === quotationToSend.departureDepositZoneId">{{ departure.address }}<br>{{ departure.postalCode }} {{ departure.city }}</span>
                  <div v-if="getDepartureAddressById(quotationToSend.departureDepositZoneId) && getDepartureAddressById(quotationToSend.departureDepositZoneId).latitude && getDepartureAddressById(quotationToSend.departureDepositZoneId).longitude">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ getDepartureAddressById(quotationToSend.departureDepositZoneId).latitude }} , {{ getDepartureAddressById(quotationToSend.departureDepositZoneId).longitude }}
                  </div>
                </b-card-text>
              </div>
              <div v-else>
                <b-card-text>
                  <span>{{ quotationToSend.departureDepositZoneAddress.address }}<br>{{ quotationToSend.departureDepositZoneAddress.postalCode }} {{ quotationToSend.departureDepositZoneAddress.city }}</span>
                  <div>
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ quotationToSend.departureDepositZoneAddress.latitude }} , {{ quotationToSend.departureDepositZoneAddress.longitude }}
                  </div>
                </b-card-text>
              </div>
            </b-col>
            <b-col sm="6" class="mb-2">
              <b-card-sub-title class="mb-1"><font-awesome-icon icon="plane" class="mr-50" style="width: 25px" /><span>{{ $t('trip.shop.arrival_deposit_zone') }}</span></b-card-sub-title>
              <div v-if="quotationToSend.arrivalDepositZoneId > 0">
                <b-card-text v-for="arrival in product.arrivalDepositZones" :key="arrival.id">
                  <span v-if="arrival.id === quotationToSend.arrivalDepositZoneId">{{ arrival.address }}<br>{{ arrival.postalCode }} {{ arrival.city }}</span>
                  <div v-if="getArrivalAddressById(quotationToSend.arrivalDepositZoneId) && getArrivalAddressById(quotationToSend.arrivalDepositZoneId).latitude && getArrivalAddressById(quotationToSend.arrivalDepositZoneId).longitude">
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ getArrivalAddressById(quotationToSend.arrivalDepositZoneId).latitude }} , {{ getArrivalAddressById(quotationToSend.arrivalDepositZoneId).longitude }}
                  </div>
                </b-card-text>
              </div>
              <div v-else>
                <b-card-text>
                  <span>{{ quotationToSend.arrivalDepositZoneAddress.address }}<br>{{ quotationToSend.arrivalDepositZoneAddress.postalCode }} {{ quotationToSend.arrivalDepositZoneAddress.city }}</span>
                  <div>
                    <span class="font-italic">{{ $t('common.note') }}</span> {{ $t('trip.shop.gps_note') }}
                    {{ quotationToSend.arrivalDepositZoneAddress.latitude }} , {{ quotationToSend.arrivalDepositZoneAddress.longitude }}
                  </div>
                </b-card-text>
              </div>
            </b-col>
          </b-row>
          <b-row class="mb-3 mt-2">
            <b-col>
              <b-card-sub-title class="mb-1">{{ $t('trip.shop.helicopter.title') }} : {{ product.name | trans }}</b-card-sub-title>
              <b-card-text v-if="regularFlight && quotationData.departureDepositZoneId !== 0 && quotationData.arrivalDepositZoneId !== 0"><font-awesome-icon icon="clock" class="mr-50" style="width: 25px" /><span>{{ $t('trip.shop.flying_duration') }} : {{ product.flyingTime }} {{ $t('common.min') }}</span></b-card-text>
              <b-card-text><font-awesome-icon icon="users" class="mr-50" style="width: 25px" /><span>{{ product.transfer.passengers }} {{ (product.transfer.passengers > 1) ? $t('trip.shop.passengers') : $t('trip.shop.passenger') }}</span></b-card-text>
              <b-card-text><font-awesome-icon icon="suitcase" class="mr-50" style="width: 25px" /><span>{{ product.transfer.luggage }} {{ (product.transfer.luggage > 1) ? $t('luggage.luggage') : $t('luggage.luggage') }}</span></b-card-text>
              <b-card-text><font-awesome-icon icon="bag-shopping" class="mr-50" style="width: 25px" /><span>{{ product.transfer.bags }} {{ (product.transfer.bags > 1) ? $t('luggage.bags') : $t('luggage.bags') }}</span></b-card-text>
              <b-card-text>
                <span v-if="regularFlight && quotationData.departureDepositZoneId !== 0 && quotationData.arrivalDepositZoneId !== 0" class="resume-price">{{ product.minPrice.displayPriceIncludingTaxes | priceFormat }} {{ $t('trip.shop.cart.incl_taxes') }}</span>
                <span v-else class="resume-price">{{ $t('trip.shop.on_quote') }}</span>
              </b-card-text>
            </b-col>
          </b-row>
          <b-row v-if="quotationToSend.departureTaxiServiceOption" class="mb-3 mt-2">
            <b-col>
              <b-card-sub-title class="mb-1">{{ $t('trip.shop.departure_extra') }}</b-card-sub-title>
              <b-card-text><span>{{ $t('trip.shop.taking_charge_from') }} : {{ quotationToSend.departureTaxiServiceOption.address.address }}, {{ quotationToSend.departureTaxiServiceOption.address.postalCode }}, {{ quotationToSend.departureTaxiServiceOption.address.city }}</span></b-card-text>
              <b-card-text><span>{{ $t('trip.shop.passengers') }} : {{ quotationToSend.departureTaxiServiceOption.passenger }}</span></b-card-text>
              <b-card-text><span class="resume-price">{{ $t('trip.shop.on_quote') }}</span></b-card-text>
            </b-col>
          </b-row>
          <b-row v-if="quotationToSend.arrivalTaxiServiceOption" class="mb-3 mt-2">
            <b-col>
              <b-card-sub-title class="mb-1">{{ $t('trip.shop.arrival_extra') }}</b-card-sub-title>
              <b-card-text><span>{{ $t('trip.shop.taking_charge_from') }} : {{ quotationToSend.arrivalTaxiServiceOption.address.address }}, {{ quotationToSend.arrivalTaxiServiceOption.address.postalCode }}, {{ quotationToSend.arrivalTaxiServiceOption.address.city }}</span></b-card-text>
              <b-card-text><span>{{ $t('trip.shop.passengers') }} : {{ quotationToSend.arrivalTaxiServiceOption.passenger }}</span></b-card-text>
              <b-card-text><span class="resume-price">{{ $t('trip.shop.on_quote') }}</span></b-card-text>
            </b-col>
          </b-row>
          <b-row v-if="quotationToSend.luggageTransferOption" class="mb-3 mt-2">
            <b-col>
              <b-card-sub-title class="mb-1">{{ $t('trip.shop.separate_luggage_transfer') }}</b-card-sub-title>
              <b-card-text><span>{{ $t('trip.shop.taking_charge_from') }} : {{ quotationToSend.luggageTransferOption.from.address }}, {{ quotationToSend.luggageTransferOption.from.postalCode }}, {{ quotationToSend.luggageTransferOption.from.city }}</span></b-card-text>
              <b-card-text><span>{{ $t('common.to') }} : {{ quotationToSend.luggageTransferOption.to.addressName }}</span></b-card-text>
              <b-card-text><span>{{ $t('luggage.luggage') }} : {{ quotationToSend.luggageTransferOption.luggage }}</span></b-card-text>
              <b-card-text><span>{{ $t('luggage.bags') }} : {{ quotationToSend.luggageTransferOption.bags }}</span></b-card-text>
              <b-card-text><span class="resume-price">{{ $t('trip.shop.on_quote') }}</span></b-card-text>
            </b-col>
          </b-row>
          <!-- FOOTER -->
          <div class="mt-3">
            <b-button class="mb-1 w-100" type="submit" @click.prevent="onSubmit()">
              <font-awesome-icon class="mr-50" icon="calendar-check" />
              {{ $t('trip.shop.helicopter.action.send_request') }}
            </b-button>
            <b-button class="w-100" variant="outline-secondary" @click="resume = false">
              <font-awesome-icon class="mr-50" icon="ban" />
              {{ $t('action.cancel') }}
            </b-button>
          </div>
        </b-overlay>
      </b-card-body>
    </template>
  </b-sidebar>
</template>
<script>
import GmapCluster from 'vue2-google-maps/dist/components/cluster'
import UIHelper from '@/helpers/ui'
import { intersection } from 'lodash'
import { postRequestOnQuoteFlight, postRequestRegularFlight } from '@/request/globalApi/requests/tripStepEventRequests'

export default {
  name: 'SidebarHelicopter',
  components: {
    // eslint-disable-next-line vue/no-unused-components
    cluster: GmapCluster,
  },
  mixins: [UIHelper],
  model: {
    prop: 'isHelicopterSidebarActive',
    event: 'update:is-helicopter-sidebar-active',
  },
  props: {
    isHelicopterSidebarActive: {
      type: Boolean,
      required: true,
    },
    product: {
      type: Object,
      default: () => ({}),
    },
    eventDetail: {
      type: Object,
      default: () => ({}),
    },
    regularFlight: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      tripId: null,
      requestSending: false,
      error: true,
      googleMapExternalUrl: 'https://maps.google.com/?q=',
      eventDepartureUtcOffset: 0,
      eventArrivalDateTime: null,
      eventArrivalUtcOffset: 0,
      departureDepositZoneOptions: [
        { id: 0, address: this.$t('aircraft.select_other'), formattedAddress: this.$t('aircraft.select_other') },
      ],
      arrivalDepositZoneOptions: [
        { id: 0, address: this.$t('aircraft.select_other'), formattedAddress: this.$t('aircraft.select_other') },
      ],
      resume: false,
      departureExtra: false,
      arrivalExtra: false,
      acceptSeparateLuggage: false,
      alerts: {
        departureExtra: false,
        arrivalExtra: false,
        luggageTransferOption: {
          from: false,
          to: false
        },
      },
      quotationData: {
        departureDepositZoneId: null,
        arrivalDepositZoneId: null,
        departureDepositZoneAddress: null,
        arrivalDepositZoneAddress: null,
        departureTaxiServiceOption: {
          address: null,
          passenger: 1,
        },
        arrivalTaxiServiceOption: {
          address: null,
          passenger: 1,
        },
        luggageTransferOption: {
          from: null,
          to: null,
          luggage: 0,
          bags: 0,
        },
        additionalInformations: '',
      },
      quotationToSend: null,
    }
  },
  watch: {
    quotationData: {
      handler(value) {
        ((value.departureDepositZoneId > 0 || value.departureDepositZoneAddress) && (value.arrivalDepositZoneId > 0 || value.arrivalDepositZoneAddress))
          ? this.error = false : this.error = true
      },
      deep: true,
    },
  },
  mounted() {
    this.tripId = this.$route.params.trip_id
    if (this.product.departureDepositZones?.length > 0) {
      this.product.departureDepositZones.forEach(address => {
        address.formattedAddress = this.getAddressFormat(address)
      })
      this.departureDepositZoneOptions.unshift(...this.product.departureDepositZones)
    }

    if (this.product.arrivalDepositZones?.length > 0) {
      this.product.arrivalDepositZones.forEach(address => {
        address.formattedAddress = this.getAddressFormat(address)
      })
      this.arrivalDepositZoneOptions.unshift(...this.product.arrivalDepositZones)
    }

    if (this.eventDetail.transferDeparture.address) {
      this.quotationData.departureDepositZoneAddress = this.eventDetail.transferDeparture.address
    }

    if (this.eventDetail.transferArrival.address) {
      this.quotationData.arrivalDepositZoneAddress = this.eventDetail.transferArrival.address
    }

    if (this.product.flyingTime) {
      this.eventArrivalDateTime = this.$moment.utc(this.eventDetail.transferDeparture.eventDateTime).add(this.product.flyingTime, 'minutes')
    }

    this.acceptSeparateLuggage = this.eventDetail.separatedLuggage
  },
  updated() {
    const { passengers } = this.product.transfer
    this.quotationData.departureTaxiServiceOption.passenger = passengers
    this.quotationData.arrivalTaxiServiceOption.passenger = passengers
    this.quotationData.luggageTransferOption.luggage = this.product.transfer.luggage
    this.quotationData.luggageTransferOption.bags = this.product.transfer.bags
  },
  methods: {
    onChangeDepartureSeparateLuggageAddress(element) {
      if (!element.target.value) {
        this.quotationData.luggageTransferOption.from = null
        this.alerts.luggageTransferOption.from = false
      } else {
        this.alerts.luggageTransferOption.from = true
      }
    },
    onChangeArrivalSeparateLuggageAddress(element) {
      if (!element.target.value) {
        this.quotationData.luggageTransferOption.to = null
        this.alerts.luggageTransferOption.to = false
      } else {
        this.alerts.luggageTransferOption.to = true
      }
    },
    onChangeDepartureExtraAddress(element) {
      if (!element.target.value) {
        this.quotationData.departureTaxiServiceOption.address = null
        this.alerts.departureExtra = false
      } else {
        this.alerts.departureExtra = true
      }
    },
    onChangeArrivalExtraAddress(element) {
      if (!element.target.value) {
        this.quotationData.arrivalTaxiServiceOption.address = null
        this.alerts.arrivalExtra = false
      } else {
        this.alerts.arrivalExtra = true
      }
    },
    setDepartureAddressPlace(place) { this.setAPIPlace(place, 'departureDepositZoneAddress', '') },
    setDepartureOnQuoteAddressPlace(place) { this.setAPIPlace(place, 'departureDepositZoneAddress', '') },
    setArrivalAddressPlace(place) { this.setAPIPlace(place, 'arrivalDepositZoneAddress', '') },
    setArrivalOnQuoteAddressPlace(place) { this.setAPIPlace(place, 'arrivalDepositZoneAddress', '') },
    setDepartureExtraAddressPlace(place) { this.setAPIPlace(place, 'departureTaxiServiceOption', 'address') },
    setArrivalExtraAddressPlace(place) { this.setAPIPlace(place, 'arrivalTaxiServiceOption', 'address') },
    setTransferFromAddressPlace(place) { this.setAPIPlace(place, 'luggageTransferOption', 'from') },
    setTransferToAddressPlace(place) { this.setAPIPlace(place, 'luggageTransferOption', 'to') },
    setAPIPlace(place, target, subTarget) {
      let streetNumber = null
      let route = null
      let postalCode = null
      let countryName = null
      let countryCode = null
      let locality = null
      let address = null
      const latitude = place.geometry.location.lat()
      const longitude = place.geometry.location.lng()
      const timeZone = place.utc_offset_minutes
      if (target === 'departureDepositZoneAddress') {
        this.eventDepartureUtcOffset = timeZone
      } else if (target === 'arrivalDepositZoneAddress') {
        this.eventArrivalUtcOffset = timeZone
      }

      place.address_components.forEach(addr => {
        if (addr.types.includes('street_number')) streetNumber = addr.short_name
        else if (addr.types.includes('route')) route = addr.short_name
        else if (addr.types.includes('postal_code')) postalCode = addr.short_name
        else if (addr.types.includes('country')) {
          countryName = addr.long_name
          countryCode = addr.short_name
        } else if (intersection(addr.types, ['locality', 'postal_town', 'sublocality_level_1', 'route']).length) locality = addr.short_name
      })

      if (route) {
        streetNumber ? (address = `${streetNumber} ${route}`) : (address = route)
      } else {
        address = place.name
      }
      const addressObject = {
        addressName: place.name,
        address,
        postalCode: postalCode ?? '00000',
        city: locality,
        countryCode,
        countryName,
        latitude,
        longitude,
        url: `${this.googleMapExternalUrl}${latitude},${longitude}`,
        timeZone,
      }

      subTarget !== '' ? this.quotationData[target][subTarget] = addressObject : this.quotationData[target] = addressObject
    },
    isValidForm() {
      return this.arrivalExtraValid() && this.departureExtraValid() && this.separateLugguageValid()
    },
    arrivalExtraValid() {
      return !this.arrivalExtra || (this.arrivalExtra && this.quotationData.arrivalTaxiServiceOption.address)
    },
    departureExtraValid() {
      return !this.departureExtra || (this.departureExtra && this.quotationData.departureTaxiServiceOption.address)
    },
    separateLugguageValid() {
      return !this.acceptSeparateLuggage || (this.acceptSeparateLuggage && this.quotationData.luggageTransferOption.from && this.quotationData.luggageTransferOption.to)
    },
    validationForm() {
      if (!this.isValidForm()) {
        return;
      }

      this.quotationToSend = this._cloneDeep(this.quotationData)
      if (this.quotationData.departureDepositZoneId === 0) this.quotationToSend.departureDepositZoneId = null
      if (this.quotationData.arrivalDepositZoneId === 0) this.quotationToSend.arrivalDepositZoneId = null
      if ((this.departureExtra && !this.quotationToSend.departureTaxiServiceOption.address) || !this.departureExtra) this.quotationToSend.departureTaxiServiceOption = null
      if ((this.arrivalExtra && !this.quotationToSend.arrivalTaxiServiceOption.address) || !this.arrivalExtra) this.quotationToSend.arrivalTaxiServiceOption = null
      if ((this.acceptSeparateLuggage && (!this.quotationToSend.luggageTransferOption.from || !this.quotationToSend.luggageTransferOption.to)) || !this.acceptSeparateLuggage) this.quotationToSend.luggageTransferOption = null
      this.resume = true
    },
    onSubmit() {
      this.requestSending = true
      const tripStepEventId = this.$route.params.event_id
      const requiredOrganizationId = this.$route.params.required_organization_id
      const helicopterVariantId = this.product.mainVariant.id
      if (this.regularFlight) {
        postRequestRegularFlight(tripStepEventId, requiredOrganizationId, helicopterVariantId, this.quotationToSend)
          .then(() => {
            this.requestSending = false
            this.resume = false
            this.$emit('update:is-helicopter-sidebar-active', false)
          })
          .catch(() => {
            this.requestSending = false
          })
      } else {
        postRequestOnQuoteFlight(tripStepEventId, requiredOrganizationId, helicopterVariantId, this.quotationToSend)
          .then(() => {
            this.requestSending = false
            this.resume = false
            this.$emit('update:is-helicopter-sidebar-active', false)
          })
          .catch(() => {
            this.requestSending = false
          })
      }
    },
    getDetailedAddress(address) {
      return `${address.address}, ${address.postalCode !== '00000' ? address.postalCode : ''} ${address.city}, ${address.countryName}`
    },
    getArrivalAddressById(addressId) {
      let arrivalAddress = null

      this.arrivalDepositZoneOptions.forEach(option => {
        if (option.id === addressId) {
          arrivalAddress = option
        }
      })

      return arrivalAddress
    },
    getDepartureAddressById(addressId) {
      let departureAddress = null

      this.departureDepositZoneOptions.forEach(option => {
        if (option.id === addressId) {
          departureAddress = option
        }
      })

      return departureAddress
    },
    getAddressFormat(address) {
      let finalAddress = address.address
      if (address.postalCode !== '00000') {
        finalAddress = `${finalAddress}, ${address.postalCode}`
      }

      return `${finalAddress}, ${address.city}`
    },
  },
}
</script>

<style lang="scss">
  .input {
    width: 90%;
  }
  .resume-price {
    border: 1px solid #DCC181;
    border-radius: 5px;
    padding: 4px 10px;
  }
  .pac-container {
    z-index: 9999;
  }
  .search-autocomplete_map {
    width: 100%;
    > .form-group > div {
      width: 90%;
    }
  }
  .select-options {
    ul, li {
      z-index: 9999;
    }
  }
</style>
